<template>
    <div class="main">
        <div class="topBg"></div>
        <div class="totalAmount">￥{{ (parseFloat(settled.avail_amount + unSettle.avail_amount)/100).toFixed(2) }}</div>
        <div class="totalAmountTip">账户总资产</div>
        <div class="detailBox">
            <div class="item">
                <div class="txt">￥{{ (parseFloat(unSettle.avail_amount)/100).toFixed(2) }}</div>
                <div class="tip">待结算</div>
            </div>
            <div class="item">
                <div class="txt">￥{{ (parseFloat(settled.avail_amount)/100).toFixed(2) }}</div>
                <div class="tip" @click="applyWithdraw(2)">已结算，去提现 ></div>
            </div>
        </div>
        <div class="alipayBox">
            <div class="alipayTip">支付宝</div>
            <div v-if="withdrawAccount" class="alipayHadBind" @click="bindAccount()">{{ withdrawAccount }}</div>
            <div v-else class="alipayUnBind" @click="bindAccount()">绑定支付宝</div>
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
// import { Toast } from 'vant'

export default {
    data() {
        return {
            settled: {},
            unSettle: {},
            withdrawAccount: '',
        }
    },
    methods: {
        initData() {
            doRequest('/account/detail', {
                currency: 1,
            }, this.$route.query).then(res => {
                this.unSettle = res.detail
            })

            doRequest('/account/detail', {
                currency: 2,
            }, this.$route.query).then(res => {
                this.settled = res.detail
            })

            doRequest('/withdraw/getAccount', {}, this.$route.query).then(res => {
                this.withdrawAccount = res.account
            })
        },
        bindAccount() {
            this.$router.push({
                path: '/withdraw/bindAccount',
                query: this.$route.query,
            })
        },
        applyWithdraw(currency) {
            let query = this.$route.query
            query.currency = currency
            this.$router.push({
                path: '/withdraw/apply',
                query,
            })
        },
    },
    mounted() {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 375px;
    height: 812px;
    background: #F5F5F5;
}

.topBg {
    width: 375px;
    height: 241.5px;
    background: url('../../assets/income/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.totalAmount {
    /* width: 130px; */
    /* height: 24px; */
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: -136px;
}

.totalAmountTip {
    width: 60px;
    height: 11.5px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 19px;
}

.detailBox {
    width: 351px;
    height: 115px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 28.5px;
    display: flex;
}

.detailBox .item {
    width: 175.5px;
    height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detailBox .item .txt {
    height: 14px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.detailBox .item .tip {
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-top: 19.5px;
}

.alipayBox {
    width: 351px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.alipayTip {
    /* width: 45px; */
    height: 14.5px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 15px;
    /* margin-left: 9.5px; */
}

.alipayHadBind {
    height: 14.5px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 15px;
    /* margin-right: 25px; */
    /* background: red; */
}

.alipayUnBind {
    height: 14.5px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #F6466C;
    line-height: 15px;
}

.edit {
    width: 7px;
    height: 11px;
    margin-left: 6px;
    background: url('../../assets/edit.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>